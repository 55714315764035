
.uploaded-image-desktop{
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-position: top;
  
  
  /* background-image: url('https://media.karousell.com/media/photos/products/2022/8/22/anya_forget_cosplay_set_1661139287_c58cf39a_progressive.jpg'); */
  background-repeat: no-repeat;
  background-size: 100%;
  /* put the height and width of your image here */
  border: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;

}

.uploaded-image-mobile{
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-position: top;
    
  /* background-image: url('https://media.karousell.com/media/photos/products/2022/8/22/anya_forget_cosplay_set_1661139287_c58cf39a_progressive.jpg'); */
  background-repeat: no-repeat;
  background-size: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;

}

.button-delete{
  position:absolute;
  bottom: 0px;
  width: 100%;
  background-color: black;
  border: 0px;
  left: 0px;
  top: 100%;
}

.radio-inbutton-image{
  position:absolute;
  top: 7px;
  left: 5px;
}
.semi-transparent-button 
{ display: block; box-sizing: border-box; margin: 0 auto; padding: 8px; width: 100%; max-width: 200px; background: #fff; /* fallback color for old browsers */background: rgba(0, 0, 0, 0.7); border-radius: 8px; color: #fff; text-align: center; text-decoration: none; letter-spacing: 1px; transition: all 0.3s ease-out; }

.border-top-zero{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.icon-right{
  position:absolute;
  top: 7px;
  right: 3px;
  color: white;
  font-size: 14px;
}

.button-div-height-desktop{
  height: 30px;
}
.button-div-height-mobile{
  height: 30px;
}

.cover-text{
  position:absolute;
  top: 6.5px;
  left: 20px;
  font-family: 'Montserrat', 'Source Sans Pro', sans-serif;
  font-size: 11px;
  font-color: black;
  font-stretch: expanded
}