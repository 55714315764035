/* notify/Notification/Notification.module.css */

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

.container {
  /* overflow: hidden; */
  max-height: 300px;
  transition: max-height 0.3s ease-out;
}

.container:not(:last-child) {
  margin-bottom: 8px;
}

.container.shrink {
  max-height: 0;
}

.notification {

  max-width: 430px;
  /* overflow: hidden; */
  padding: 12px 48px 12px 12px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 24px;
  font-weight: bold;
  transition: transform 0.3s ease-out;
  border-radius: 30px;
  position: relative;
}

.notification:not(:last-child) {
  margin-bottom: 8px;
}

.notification.info {
  background-color: #565674;  

}

.notification.message {
  background-color: #565674;
}

.notification.success {
  background-color: #4caf50;
}

.notification.warning {
  background-color: #ff9800;
}

.notification.error {
  background-color: #f44336;
}

.notification.slideIn {
  animation-name: slideIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

.notification.slideOut {
  transform: translateX(150%);
  flex: 0;
}

.notification .closeButton {
  position: absolute;
  top: 8px;
  left: 8px;
  background: #565674;
  padding: 7px 7px;
  border-radius: 100%;
  font-size: 7px;
  text-align: center;
  border: solid 0.5px;
  border-color: white;
  cursor: pointer;
}

.notification, .notification .closeButton {
  color: #fff;
}
