.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: auto;
  max-width: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow-x: auto;
}

.button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-c {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.form-table {
  width: 100%;
  border-collapse: collapse;
}

.form-table th,
.form-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.form-table th {
  background-color: #f4f4f4;
}

.btn {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  text-align: center;
  margin: 20px;
}
