.form-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.form-list li {
  margin: 10px 0;
}

.form-list2 {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
  margin-bottom: 2px;
}

.form-list2 li {
  margin: 0px 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  position: relative;
  max-height: 80vh; /* Limit the height to 80% of the viewport height */
  overflow-y: auto; /* Allow vertical scrolling */
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.button-options {
  display: flex;
  justify-content: space-around;
}

.option-button {
  background-color: #2196f3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option-button:hover {
  background-color: #1976d2;
}

.active-view {
  margin-top: 20px;
}

/* Button Styles */
.button-a,
.button-b {
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  margin: 0 10px; /* Combined margin for consistency */
  transition: background-color 0.3s;
}

.button-b {
  background-color: #009ef7;
}

.button-a {
  background-color: #f1faff;
  color: #333;
}

.button-a:hover,
.button-b:hover,
.option-button:hover {
  background-color: #8eb9e7; /* Unified hover effect */
}

.form-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  vertical-align: middle;
}

.form-table th {
  color: #333;
  text-align: center;
  font-weight: bold;
  padding: 12px 15px;
}

.form-table td {
  padding: 10px 15px;
  text-align: center;
}

.form-table tr:nth-child(even) {
  background-color: #bfb9b9;
}

.form-table tr:hover {
  background-color: #e0e0e0;
}

.form-table label {
  font-weight: bold;
}

.modal-close-btn:hover {
  background: none;
  color: inherit;
}
.button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.checkbox-group {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.checkbox-item {
  display: flex;
  align-items: center;
  width: 50%;
  box-sizing: border-box;
  padding: 8px;
  cursor: pointer;
}

.checkbox-item label {
  margin-left: 8px;
}

input[type="checkbox"] {
  cursor: pointer;
  transform: scale(1.2);
  margin-right: 5px;
}

.checkbox-item {
  pointer-events: auto;
}
input[type="text"],
input[type="number"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 0px;
  margin-top: 5px;
}
.error-message {
  color: red;
  margin-top: 5px;
}

/* Styles for print */
@media print {
  .modal-overlay {
    display: none; /* Hide overlay when printing */
  }

  #print-content {
    font-size: 120px; /* Increased font size for better readability */
    font-weight: bold; /* Bold text for emphasis */
    color: #000; /* Black text for high contrast */
    line-height: 1.5; /* Increased line height */
    padding: 10px; /* Ensure padding */
    text-align: left;
  }

  .form-list {
    padding: 0; /* No padding */
    margin: 0; /* No margin */
  }

  .form-list li {
    margin-bottom: 50px; /* Increased space between items */
    font-size: 120px; /* Increased font size of list items */
    color: #000; /* Black text for clarity */
  }

  h1 {
    font-size: 200px; /* Larger header size for printing */
    margin-bottom: 0px; /* Space below header */
    color: #000; /* Ensure header is black */
    margin-left: 0px;

    text-align: left;
  }

  .signature-container {
    margin-top: 100px; /* Space above signature */
  }

  .signature-a,
  .signature-b {
    font-size: 120px; /* Ensure signatures are large */
    color: #000; /* Black text for signatures */
  }
  .signature-b {
    margin-left: 100px; /* Move 50px to the left when printing */
  }
}
