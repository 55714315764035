//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import './core/vendors/plugins/prismjs';
@import './core/vendors/plugins/formvalidation';
