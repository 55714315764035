//
// Print Mode
//

// Add .print-content-only class to body element in order to allow printing only the content area
@media print {
    .print-content-only {
        padding: 0 !important;
        background: none !important;

        .docs-wrapper,
        .docs-page,
        .docs-page-title
        .docs-content,
        .container,
        .container-fluid {
            background: none !important;
            padding: 0 !important;
            margin: 0 !important;
        }

        .docs-aside,docs-
        .scrolltop,
        .docs-header,
        .drawer,
        .btn {
            display: none !important;
        }
    }
}
