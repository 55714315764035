.toolbar {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  height: auto !important;
  padding: 0px 0px !important; 
background-color: #F3F5F8;
opacity: 1;
  // height: 42px !important;
}

// .sv-header {
//   // background-color: white;
//   background-color: #F5F7F8;
//   ;

//   //widht fill window
//   width: 100%;
//   font-size: 18px;
// }

.w-fit {
  width: fit-content;
}

.flipX {
  transform: scaleX(-1);
}

.rotate180 {
  transform: rotate(180deg);
}