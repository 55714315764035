.content-header {
    font-size: 2rem;
    font-weight: 600;
}

.content-body {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    display: flex;
    gap: 1rem;
}

.card-summary{
    padding: 20px;
    border: 2px dashed #d9d9d9;
    border-radius: 10px;
  }

.summary {
    font-size: 2rem;
    font-weight: 600;
}

.summary-label {
    font-size: 1.5rem;
    font-weight: 500;
    color: #b5b5c3;
    padding-bottom: 0.5rem;
}

@media (min-width: 768px) {
    .summary-label {
        font-size: 1rem;
    }
  }
  
.summary-detail {
    font-size: 1rem;
    font-weight: 500;
    display:flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px dashed lightgray;
    padding: 3px 0;
}