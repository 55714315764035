.multiselect-inner-wrapper {
  border: none !important;
}

.multiselect-inner-wrapper .multiselect-selected-item {
  background: #d9d9d9 !important;
  color: black !important;
}

.multiselect-inner-wrapper .multiselect-selected-item .remove-btn {
  border: none !important;
  color: gray;
  margin-left: 12px;
  width: auto;
  height: auto;
  cursor: pointer;
}

.multiselect-edit-wrapper {
    margin: 0px !important;
    margin-top: 5px !important;
    height: 40px !important;
    background-color: white;
    border-radius: 0.25rem;
}

/* Edit Field */
.edit-input-field {
    background-color: white;
}

.edit-input-field > input {
    padding: 10px !important;
    background-color: white;
    border:none;
}

.edit-confirm-button {
    display: none !important;
}

.edit-discard-button {
    background: white !important;
    color: gray !important;
}

input {
  font-size: 1rem !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: hsl(0, 0%, 60%) !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: hsl(0, 0%, 60%) !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: hsl(0, 0%, 60%) !important;
}
