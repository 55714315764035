.colorblue {
  background-color: #a1ddf9;
}

.cl-gray {
  background-color: #d6d6d6;
}

.cl-graywhite {
  background-color: #ffffff;
}

.cl-graysilver {
  background-color: #d9d9d9;
}

.cl-mateblue {
  background-color: #a1ddf9;
}

.cl-tokopedia {
  background-color: #84c468;
}

.cl-whatsapp {
  /* background-color: #199844; */
  background-color: #45bd6e;
}

.cl-chat-whatsapp {
  background-color: #dcf8c6;
}

.cl-shopee {
  background-color: #ee4e2c;
}

.cl-lazada {
  background-color: #191e72;
}

.cl-sabunzone {
  background-color: #f8edcc;
}

.cl-blibli {
  background-color: #008bd2;
}

.cl-7days {
  background-color: #dff5ff;
}

.cl-7days-in {
  background-color: #565674;
}

.cl-darkblue {
  background-color: #150958;
}

.audio-cl-7days::-webkit-media-controls-panel {
  background-color: #a1ddf9;
}

/* Set Up Audio UI Whatsapp*/
.audio-cl-whatsapp::-webkit-media-controls-panel {
  /* background-color: #32e186; */
  background-color: #45bd6e;
}

.audio-cl-whatsapp::-webkit-media-controls-mute-button {
  /* background-color: #ffffff23; */
  border-radius: 50%;
}

.audio-cl-whatsapp::-webkit-media-controls-play-button {
  /* background-color: #ffffff1d;  */
  border-radius: 50%;
}

.audio-cl-whatsapp::-webkit-media-controls-play-button:hover {
  /* background-color: rgb(255, 255, 255); */
  background-color: #ffffff53;
  opacity: 50%;
}

/* 
.audio-cl-whatsapp::-webkit-media-controls-current-time-display {
  color: #fff;
}

.audio-cl-whatsapp::-webkit-media-controls-time-remaining-display {
  color: #fff;
} */

.audio-cl-whatsapp::-webkit-media-controls-timeline {
  /* background-color: #ffffff20; */
  border-radius: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.audio-cl-whatsapp::-webkit-media-controls-volume-slider {
  /* background-color: #ffffff1f; */
  border-radius: 25px;
  padding-left: 8px;
  padding-right: 8px;
}

/* Set Up Audio UI Tokopedia*/
.audio-cl-tokopedia::-webkit-media-controls-panel {
  background-color: #84c468;
}

.audio-cl-tokopedia::-webkit-media-controls-mute-button {
  /* background-color: #ffffff53; */
  border-radius: 50%;
}

.audio-cl-tokopedia::-webkit-media-controls-play-button {
  /* background-color: #ffffff53; */
  border-radius: 50%;
}

.audio-cl-tokopedia::-webkit-media-controls-play-button:hover {
  /* background-color: rgb(255, 255, 255); */
  background-color: #ffffff53;
  opacity: 50%;
}

/* 
.audio-cl-tokopedia::-webkit-media-controls-current-time-display {
  color: #fff;
}

.audio-cl-tokopedia::-webkit-media-controls-time-remaining-display {
  color: #fff;
} */

.audio-cl-tokopedia::-webkit-media-controls-timeline {
  /* background-color: #ffffff53; */
  border-radius: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.audio-cl-tokopedia::-webkit-media-controls-volume-slider {
  /* background-color: #ffffff53; */
  border-radius: 25px;
  padding-left: 8px;
  padding-right: 8px;
}

/* Set Up Audio UI Shopee*/
.audio-cl-shopee::-webkit-media-controls-panel {
  background-color: #ee4e2c;
}

.audio-cl-shopee::-webkit-media-controls-mute-button {
  /* background-color: #ffffff53; */
  border-radius: 50%;
}

.audio-cl-shopee::-webkit-media-controls-play-button {
  /* background-color: #ffffff53; */
  border-radius: 50%;
}

.audio-cl-shopee::-webkit-media-controls-play-button:hover {
  /* background-color: rgb(255, 255, 255); */
  background-color: #ffffff53;
  opacity: 50%;
}

/* 
.audio-cl-shopee::-webkit-media-controls-current-time-display {
  color: #fff;
}

.audio-cl-shopee::-webkit-media-controls-time-remaining-display {
  color: #fff;
} */

.audio-cl-shopee::-webkit-media-controls-timeline {
  /* background-color: #ffffff53; */
  border-radius: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.audio-cl-shopee::-webkit-media-controls-volume-slider {
  /* background-color: #ffffff53; */
  border-radius: 25px;
  padding-left: 8px;
  padding-right: 8px;
}

/* Set Up Audio UI sabunzone*/
.audio-cl-sabunzone::-webkit-media-controls-panel {
  background-color: #f8edcc;
}

.audio-cl-shopee::-webkit-media-controls-mute-button {
  /* background-color: #ffffff53; */
  border-radius: 50%;
}

.audio-cl-shopee::-webkit-media-controls-play-button {
  /* background-color: #ffffff53; */
  border-radius: 50%;
}

.audio-cl-shopee::-webkit-media-controls-play-button:hover {
  /* background-color: rgb(255, 255, 255); */
  background-color: #ffffff53;
  opacity: 50%;
}

.audio-cl-shopee::-webkit-media-controls-timeline {
  /* background-color: #ffffff53; */
  border-radius: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.audio-cl-shopee::-webkit-media-controls-volume-slider {
  /* background-color: #ffffff53; */
  border-radius: 25px;
  padding-left: 8px;
  padding-right: 8px;
}

/* Set Up Audio UI blibli*/
.audio-cl-blibli::-webkit-media-controls-panel {
  background-color: #008bd2;
}

.audio-cl-blibli::-webkit-media-controls-mute-button {
  /* background-color: #ffffff53; */
  border-radius: 50%;
}

.audio-cl-blibli::-webkit-media-controls-play-button {
  /* background-color: #ffffff53; */
  border-radius: 50%;
}

.audio-cl-blibli::-webkit-media-controls-play-button:hover {
  /* background-color: rgb(255, 255, 255); */
  background-color: #ffffff53;
  opacity: 50%;
}

/* .audio-cl-blibli::-webkit-media-controls-current-time-display {
  color: #fff;
}

.audio-cl-blibli::-webkit-media-controls-time-remaining-display {
  color: #fff;
} */

.audio-cl-blibli::-webkit-media-controls-timeline {
  /* background-color: #ffffff53; */
  border-radius: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.audio-cl-blibli::-webkit-media-controls-volume-slider {
  /* background-color: #ffffff53; */
  border-radius: 25px;
  padding-left: 8px;
  padding-right: 8px;
}

.border-reply-tokopedia {
  border-color: #57903f;
}

.border-reply-whatsapp {
  border-color: #16823b;
}

.border-reply-shopee {
  border-color: #a52e14;
}

.border-reply-sabunzone {
  border-color: #e0bc50;
}

.border-reply-blibli {
  border-color: #00679a;
}

.border-reply-7days {
  border-color: #034ca3;
}
