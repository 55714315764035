@import '_variable';

.step-sub-title {
    font-weight: bold;
}

.template-div {
    width: 200px;
    border-radius: 1.3em !important;
    word-wrap: break-word;

    .desc {
      font-size: 8pt;
    }
}

.channel-div {
    width: 150px;
    border-radius: 1.3em !important;
    word-wrap: break-word;

    .desc {
      font-size: 8pt;
    }
}

.space {
    justify-content: space-between;
}


.fs-custom-large {
    font-size: 6.5rem !important;
  }