.message-menu {
    padding-top: 3px;
    padding-left: 0;
    padding-right: 3px;
    padding-bottom: 0;
    position: absolute;
    // top: 20px;
    // bottom: 0px;
    right: 0;
    background: none;
  }

.message-menu-title {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 5px;
    text-align: center;
}

.message-menu-item {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.message-menu-item-timestamp {
    text-align: right;
    width: 100%;
}

.message-menu-item-timestamp-null {
    text-align: right;
    padding-right: 5px;
    width: 100%;
}

.message-menu-item-button {
    // padding-left: 5px;
    // padding-right: 5px;
    // padding-top: 0px;
    // padding-bottom: 0px;
    padding: 0px;
}

.position-menu-open {
    position: fixed !important;
}