.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay for better contrast */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff; /* White background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2); /* More prominent shadow */
  padding: 30px; /* Regular padding */
  width: 600px; /* Adjust as needed */
  /* width: 90%;*/
  text-align: left;
  overflow: hidden;
}

h2 {
  font-size: 24px; /* Normal header size for modal */
  margin-bottom: 20px;
  color: #333; /* Darker header color */
  margin-left: 0px;
  text-align: left;
}

.form-list {
  margin: 0;
  padding: 0;
  list-style-type: none; /* Remove bullet points */
}

.form-list li {
  margin-bottom: 10px; /* Normal space between items */
  font-size: 16px; /* Normal font size for list items */
  color: #444; /* Darker text color for better contrast */
}

.button-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px; /* Normal space above buttons */
}

.btn {
  padding: 10px 20px; /* Normal button padding */
  border: none;
  border-radius: 5px; /* Regular rounded corners */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s; /* Added transform transition */
}

.btn-light-primary {
  background-color: #e7e7e7; /* Lighter background */
  color: #333;
}

.btn-light-primary:hover {
  background-color: #d0d0d0; /* Darker on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.btn-primary {
  background-color: #007bff; /* Bright primary button color */
  color: #fff;
}

.btn-primary:hover {
  background-color: #0056b3; /* Darker on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.signature-container {
  margin-top: 30px; /* Regular space above signature */
  display: flex;
}

.signature-a,
.signature-b {
  width: 20%; /* Adjusted width for signatures */
}
.signature-b {
  margin-left: 100px; /* Move 50px to the left when printing */
}

/* Styles for print */
@media print {
  .modal-overlay {
    display: none; /* Hide overlay when printing */
  }

  #print-content {
    font-size: 120px; /* Increased font size for better readability */
    font-weight: bold; /* Bold text for emphasis */
    color: #000; /* Black text for high contrast */
    line-height: 1.5; /* Increased line height */
    padding: 10px; /* Ensure padding */
    text-align: left;
  }

  .form-list2 {
    padding: 0; /* No padding */
    margin: 0; /* No margin */
  }

  .form-list2 li {
    margin-bottom: 50px; /* Increased space between items */
    font-size: 120px; /* Increased font size of list items */
    color: #000; /* Black text for clarity */
  }

  h2 {
    font-size: 200px; /* Larger header size for printing */
    margin-bottom: 0px; /* Space below header */
    color: #000; /* Ensure header is black */
    margin-left: 0px;

    text-align: left;
  }

  .signature-container {
    margin-top: 100px; /* Space above signature */
  }

  .signature-a,
  .signature-b {
    font-size: 120px; /* Ensure signatures are large */
    color: #000; /* Black text for signatures */
  }
  .signature-b {
    margin-left: 100px; /* Move 50px to the left when printing */
  }
}
