html,
body {
  background-color: white;
  font-family: sans-serif;
  padding: 8px;
  table {
    width: 100%;
    text-align: center;
    border-spacing: 0px;
    td,
    th {
      background-color: white;
    }
    .column_resizer_body {
      border-left: none;
      border-right: none;
    }
    .columnResizer {
      width: 20px !important;
      min-width: 20px !important;
      max-width: 20px !important;
      padding: 0 0 0 0;
      position: relative;
    }
    .columnResizer:not(.disabled_column_resize):after {
      content: url("../images/resize_icon.svg");
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      align-content: center;
      width: 20px;
      padding: 0;
      opacity: 0;
    }
    .columnResizer:not(.disabled_column_resize):hover::after {
      content: url("../images/resize_icon.svg");
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      align-content: center;
      width: 20px;
      padding: 0;
      opacity: 1;
    }
    .column_resizer_body {
      width: 20px !important;
      min-width: 20px !important;
      max-width: 20px !important;
    }
    .lebar {
      width: 436px !important;
      max-width: 436px !important;
    }

    @media screen and (min-width: 1024px) {
      .lebar {
        width: 770px !important;

        max-width: 770px !important;
      }
    }
  }
}
