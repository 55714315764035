.detail-summary-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.detail-summary-table th,
.detail-summary-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 0.9rem;
}

.detail-summary-table th {
  background-color: #3498db;
  color: white;
}

.detail-summary-table tr:nth-child(even) {
  background-color: #ffff;
}

.detail-summary-table tr:nth-child(odd) {
  background-color: #e6f7ff;
}

.content-header {
  font-size: 2rem;
  font-weight: 600;
}

.content-body {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  gap: 1rem;
}

.card-summary {
  border-radius: 10px;
  background-color: #c6e7fd;
  padding: 1rem;
}

.summary {
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #a8dcff;
}

.summary-label {
  font-size: 1rem;
  font-weight: 500;
  color: #b5b5c3;
  padding-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .summary-label {
    font-size: 1rem;
  }
}

.summary-detail {
  font-size: 0.9rem;
  font-weight: 600;
  color: #9899ac;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0;
}