/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 500px;
  max-height: 90vh; /* Limit height to 90% of the viewport */
  overflow-y: auto; /* Allow vertical scrolling */
  position: relative;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Spacing between rows */
}

.form-row {
  display: flex;
  align-items: center; /* Align label, separator, and input */
}
.form-table {
  width: 100%; /* Full width */
  border-collapse: collapse; /* Remove gaps between cells */
  padding: 0px; /* Minimal padding */
}

.form-table tr {
  border: none; /* No border for rows */
  padding: 0px; /* Minimal padding */
}

.noPad {
  padding: 0px; /* Minimal padding */
  border: none; /* No border for cells */
  vertical-align: top; /* Align content to the top */
}
.form-label {
  flex: 1; /* Take up available space */
  font-weight: bold; /* Bold labels */
}

.form-separator {
  margin: 0 10px; /* Space between label and input */
}

.form-input {
  flex: 2; /* Adjust as needed for input width */
}

.error-message {
  color: red; /* Style for error messages */
  font-size: 0.9em; /* Smaller font for error */
}

.form-actions {
  display: flex;
  justify-content: space-between; /* Space buttons evenly */
}

.button-a,
.button-b {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-a {
  background-color: #f1faff;
  color: #333;
}

.button-b {
  background-color: #009ef7;
  color: white;
}

input[type="text"],
input[type="date"],
input[type="time"],
input[type="number"],
textarea,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 0px;
}

#signature-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* Adjust spacing between the signatures and the table */
}

.driverName,
.userName {
  margin-bottom: 10px; /* Space between name and signature */
}

.driverSignature,
.userSignature {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 10px;
}
.driverName {
  margin-left: 15px;
}
.userName {
  margin-right: 15px;
}
.driverSignature {
  margin-left: 20px;
}
.usersignature {
  margin-right: 20px;
}
/* ShipmentHandover.css */
@media print {
  #print-content {
    font-size: 24px; /* Adjust this value to your desired font size */
  }

  /* You can add other print-specific styles here as needed */
  .form-table {
    font-size: 24px; /* Adjust table font size as well */
  }

  .signature-a,
  .signature-b {
    font-size: 24px; /* Adjust signature section font size */
  }

  /* Other elements can be styled similarly */
}
