// style={{height: isTabletOrMobile ? "400px" : "55%", overflowX:"hidden", overflowY: "scroll", paddingLeft: "0px", paddingRight: "0px"}}
@import '_variable';

.chat-body-custom {
  overflow-x: hidden;
  overflow-y: scroll;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.chat-header {
  padding: 5px 5px;
  // padding-bottom: "5px",
  // paddingTop: "5px",
  // paddingLeft: "10px",
  // paddingRight: "20px",
  margin: 0px 0px;
}

.chat-footer-custom {
    padding-right: 5px;
    //height: 20%;
    max-height: 45%;
    bottom: 0px;
}
@media (min-width:$phone-width)  { /* smartphones, iPhone, portrait 480x320 phones */ 
    .chat-body-custom {
        height: 400px;
        }

    .chat-footer-custom {
        padding-left: 5px;
    }
}
//@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
//@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:$tablet-width)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .chat-body-custom {
    height: 80%;
  }

  .chat-footer-custom {
      padding-left: 20px;
  }

}

//@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
//@media (min-width:1281px) { /* hi-res laptops and desktops */ }
