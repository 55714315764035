
.uploaded-video-desktop{
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100px;
  height: 130px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  /* put the height and width of your video here */
  border: none;
  border-radius: 8px;
  box-sizing: border-box;

}

.uploaded-video-mobile{
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 50px;
  height: 80px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 8px;
  box-sizing: border-box;

}

.button-delete-video{
  bottom: 0px;
  width: 50%;
  background-color: black;
  border: 0px;
}

.radio-inbutton-video{
  position:absolute;
  top: 7px;
  left: 5px;
}
.semi-transparent-button-video
{ display: block; box-sizing: border-box; margin: 0 auto; padding: 8px; width: 11.7%; max-width: 200px; background: #fff; /* fallback color for old browsers */background: rgba(0, 0, 0, 0.7); border-radius: 8px; color: #fff; text-align: center; text-decoration: none; letter-spacing: 1px; transition: all 0.3s ease-out; }

.border-top-zero-video{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.icon-center{
  left: 0px;
  right: 0px;
  color: white;
  font-size: 14px;
}

.button-div-height-desktop{
  height: 30px;
}
.button-div-height-mobile{
  height: 30px;
}

.cover-text{
  position:absolute;
  top: 6.5px;
  left: 20px;
  font-family: 'Montserrat', 'Source Sans Pro', sans-serif;
  font-size: 11px;
  font-color: black;
  font-stretch: expanded
}

.hw-100x{
  height: 30px;
  left: 0px;
  right: 0px;
  width: 100%;
}

.dashed-button{
  border: 2.5px dashed #000000;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}