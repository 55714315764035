//
// Components
//

// Import Dependencies
@import "stepper-links";
@import "timeline-label";
@import "placeholder-custom";
@import "item-hover-custom";
@import "nouislider-custom";
@import "landing";

a.shortcut-button:active {
  color: blue
}

