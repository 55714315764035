@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a.shortcut-button:active {
  color: blue;
}

.pointer-none {
  pointer-events: none;
}

.break-word {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.button-interactive-chat {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  outline: none;
  color: #009ef7;
  padding: 0.5rem 1rem;
  background: white;
  border: 1px solid #009ef7;
  border-radius: 1.5rem;
  transition: all 100ms ease-in-out;
  width: 24%;

  span {
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  i {
    color: #009ef7;
  }

  &:hover {
    color: white;
    background-color: #009ef7;

    i {
      color: white;
    }
  }
}

$gap: 12;

@mixin col-x-list {
  @for $i from 1 through $gap {
    .gap-#{$i} {
      gap: #{$i/2}rem;
    }
    .row-gap-#{$i} {
      row-gap: #{$i/2}rem;
    }
    .col-gap-#{$i} {
      column-gap: #{$i/2}rem;
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(-25px);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.animated-logo {
  animation: bounce 2s infinite;
}

.gradient-animation {
  background: linear-gradient(270deg, #007bff, #94caff);
  background-size: 400% 400%;
  animation: gradientMove 5s ease infinite;
}

.gradient-animation:hover {
  animation-play-state: paused;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@include col-x-list;
