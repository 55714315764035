.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30%;
}

.ml-20 {
  margin-left: 20%;
}

.sv-height-100 {
  height: 100%;
}

.sv-w-fill {
  width: 100%;
}

.fa-left {
  text-align: left;
}

.fa-center {
  text-align: center;
}

.justify-start {
  justify-content: flex-start !important;
}

.margin-auto {
  margin-inline-start: auto !important;
}

.overlay-parent:hover + .hide-child {
  display: block;
}

.hide-child {
  // display: none;
  visibility: hidden;
}

.retry-message {
  align-items: center;
  align-self: center;
  padding-right: 5px;
}

.avatar-badge {
  position: absolute;
  border: 2px solid white;
  border-radius: 100%;
  top: 70%;
  left: 60%;
  // transform: translateX(-50%) translateY(-50%) !important;
}

.avatar-badge-img {
  object-fit: cover;
  object-position: center;
}

.channel-logo {
  object-fit: cover;
  object-position: center;
}

.search-toolbar {
  border-radius: 0.475rem;

}

.search-toolbar-icon {
  padding-left: 2px;
  padding-right: 2px;
  fill: black;
}

.upload-badge {
  position: absolute;
  right: 20%;
}

.display-none {
  display: none;
}