.card-header {
    font-size: 2rem;
    font-weight: 600;
}

.card-body {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.form-label {
    font-size: 1rem;
    font-weight: 600;
}